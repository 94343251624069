.entity-contacts {
  padding-top: 0.5rem;
}

.main-data-table {
  ::v-deep {
    font-size: 13px;
    th {
      &:nth-child(3) {
        width: 300px;
      }
    }
    tbody {
      td {
        cursor: pointer;
      }
    }
  }
}
