
.main-data-table {
  ::v-deep {
    font-size: 12px;
    thead {
      th {
        &:nth-child(2) {
          width: 200px;
        }
        &:nth-child(4) {
          width: 300px;
        }
        &:nth-child(6){
          width: 75px;
        }
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(7) {
          width: 150px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
