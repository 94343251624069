
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.agency-brands {
  @include agency-brand-relationships;

  ::v-deep {
    .main-data-table {
      font-size: 13px;
      thead {
        th {
          &:nth-child(4) {
            min-width: 120px;
          }
          /* Status */
          &:nth-child(5) {
            min-width: 120px;
          }
        }
      }
      tbody {
        td[colspan="6"] {
          text-align: left !important;
        }
      }
    }
  }
}
.action-button {
  @include profile-action-button;
}
