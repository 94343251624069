
@import "~@/../assets/stylesheets/variables/variables";

.response-box {
  margin: 0 0 1rem;

  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
