
@import "~@/../assets/stylesheets/variables/variables";

.response-box {
  margin: 1rem 0 .3rem;

  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
