
@import "~@/../assets/stylesheets/variables/variables";

.winbox-progress-chart {
  position: relative;
}
.winbox-progress-chart-labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  height: 220px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 21px;

  .progress-value {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }
  .progress-status {
    font-size: 21px;
    color: $winbox-secondary-title;
  }
}
