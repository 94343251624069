
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-data-table-search-columns {
  background: $winbox-primary-active-bg-color;
  margin: 0;
  position: relative;
}
.main-search-input {
  @include datatable-search-input;
}
.custom-filters-btn {
  background: $winbox-button-focus-color;
  height: auto;
  padding: 8px 13px;
  font-size: 12px;
}
.filter-modal {
  position: absolute;
  z-index: 2;
}
