
.notification-bar {
  padding: 10px;
  background-color: #B03D00;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
  height: 60px;
}
.notification-bar.success {
  background-color: #4caf50;
}
.notification-bar.error {
  background-color: #B03D00;
}
.dismiss-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}
.dismiss-button:hover {
  color: #ffdddd;
}
