// Own variables
$winbox-min-site-width: 800px;
$winbox-header-height: 60px;
$winbox-main-content-min-height: calc(100vh - #{$winbox-header-height});
$winbox-nav-width: 76px;
$winbox-sidebar-menu-item-width: 40px;
$winbox-main-content-padding: 1.5rem;
$winbox-default-column-padding: 0.75rem;

// Sizes
$winbox-font-size-small: 12px;
$winbox-font-size-less-than-main: 13px;
$winbox-font-size-main: 14px;
$winbox-font-size-base: 16px;
$winbox-font-size-average: 18px;
$winbox-font-size-big: 21px;
$winbox-font-size-bigger: 31px;
$winbox-font-size-huge: 51px;

// Colors
$winbox-body-color: #F7F7F7;
$winbox-widget-link-color: #fafafa;
$winbox-icon-bg: #E9EAEB;
$winbox-tag-border-color: #dbdbdb;
$winbox-body-grey-bg: #C3C6C9;
$winbox-primary-bg-color: #1E1E1E;
$winbox-primary-active-bg-color: #00C0B5;
$winbox-button-focus-color: #00b3a9;
$winbox-link-color: $winbox-primary-active-bg-color;
$winbox-link-hover-color: #80E0DA;
$winbox-icon-background: #CCF2F0;
$winbox-input-light-bg: $winbox-link-hover-color;
$winbox-input-placeholder: rgba(hsl(0, 0%, 21%), 0.3) !default;
$winbox-icon-negative-background: #F6CCD1;
$winbox-primary-text-color: #303133;
$winbox-nav-bg-color: #2B323A;
$winbox-sidebar-active-bg-color: #545E65;
$winbox-secondary-title: #868E96;
$winbox-progress: #007BFF;
$winbox-positive: #487A0E;
$winbox-negative: #B03D00;
$winbox-table-header: #FF3B1E;
$winbox-table-links: #EF476E;
$winbox-table-links-hover: #BC143A;
$winbox-uploaded-file: #6BBEDC;
$winbox-mapping-border-color: #53BA82;

$winbox-success: $winbox-primary-active-bg-color;
$winbox-warning: #8a6d3b;
$winbox-info: #31708f;
$winbox-error: $winbox-negative;

$winbox-search-bg: #404141;
$winbox-search-placeholder-color: #9A8A71;
$winbox-search-input-height: 40px;
$winbox-search-filter-text-color: #9D9D9D;

$winbox-border-radius: 4px;
$winbox-box-shadow-color: rgba(27, 33, 58, 0.4);
