
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-contact {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
.error-block {
  color: $winbox-error;
}
