@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.entity-location {
  @include location-detail-page-styles;
}

.create-location {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}

.pb-0 {
  padding-bottom: 0;
}
.mb-0 {
  margin-bottom: 0;
}

.action-button {
  width: 100%;

  input {
    margin-right: 5px;
  }
}
