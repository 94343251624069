
@import "~@/../assets/stylesheets/variables/variables";

.company-tags {
  padding-top: 0.5rem;
  ::v-deep {
    .wrap-speciality-widget {
      .card-content {
        height: 100%;
        max-height: 600px;
      }
    }
  }
}
