
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-social-media {
  padding: 1rem;
  ::v-deep {
    .card-content {
      @include profile-card-content-base-styles;
    }
  }
}
