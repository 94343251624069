
@import "~@/../assets/stylesheets/variables/variables";

.error-block {
  color: $winbox-error;
  margin: 0 0 1rem;
}
.login-page {
  width: 600px;
  margin: 0 auto;
}
.main-title {
  margin: 0 0 1rem;
}
.back-to-login {
  margin-top: 24px;
}
.forgot-box {
  margin: 0 0 1rem;
  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
