
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.wrap-speciality-widget {
  ::v-deep {
    .winbox-card {
      .card-header {
        background: $winbox-primary-active-bg-color;

        .card-header-icon {
          padding: 0;
        }
      }
      .card-header-title {
        justify-content: center;
      }
      .card-content {
        height: 240px;
        overflow: auto;
        padding: 0.5rem 0;

        .content {
          transition: padding-top 0.5s;
        }

        .left-search-wrap {
          &.visible {
            max-height: 50px;
            transition: max-height 0.5s ease-in;

            + .content {
              padding-top: 3rem;
            }
          }
        }
      }
    }
    .vs__dropdown-menu {
      min-width: auto;
    }
    .vs__dropdown-toggle {
      border: transparent;
    }
  }

  .child-industry {
    margin-left: 50px;
  }
}
.new-tag-input-wrap {
  padding-right: 4px;
}
.tags-list {
  li {
    border-bottom: 1px solid #dbdbdb;
    padding: 0 1rem 5px;
    margin: 0 0 5px;
    @include no-user-select;
    @include button-delete-universal-hover;

    &:last-child {
      border-bottom: 0;
    }
  }
}
.action-right {
  text-align: right;
}
.button-remove {
  background: $winbox-icon-negative-background;
  border: transparent;
  border-radius: 50%;
  font-size: 14px;

  @include button-delete-universal;
}
.left-search-wrap {
  overflow: hidden;
  color: $winbox-primary-text-color;
  width: 100%;
  font-size: 13px;

  .left-search-box {
    margin-left: 10px;
  }
}
.search-main-icon {
  margin-left: 10px;

  ::v-deep {
    .winbox-icon {
      transition: color 0.3s;
    }
  }

  &.active {
    ::v-deep {
      .winbox-icon {
        color: #fff !important;
      }
    }
  }
}
.no-data {
  padding: 0.5rem 1rem;
}
