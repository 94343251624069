
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.toggle-menu {
  position: relative;

  .toggle-menu-modal {
    position: absolute;
    padding: 5px 8px;
    background: #fff;
    right: 0;
    border-radius: $winbox-border-radius;
    width: 220px;
    border: 1px solid $winbox-icon-bg;
    box-shadow: 0 2px 5px -2px $winbox-box-shadow-color;
    z-index: 20;

    ul {
      li {
        color: $winbox-link-color;
        cursor: pointer;
        margin: 6px 0;
        font-weight: 400;

        text-align: left;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        @include no-user-select;
      }
      .item-text {
        @include text-overflow-ellipsis;
      }
    }
  }
  .menu-item-icon {
    margin-right: 3px;
  }
}
