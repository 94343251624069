@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.entity-notes {
  padding-top: 0.5rem;
  .main-data-table {
    position: relative;
    ::v-deep {
      @include entity-notes-main-table-styles;
    }
  }
}
.notes-modal {
  ::v-deep {
    .winbox-modal-header {
      background: $winbox-primary-bg-color;
    }
  }
}
