
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-title-functions {
  ::v-deep {
    .winbox-card {
      .card-header {
        background: $winbox-table-header;
      }
      .card-header-title {
        + .card-header-icon {
          display: none;
        }
      }
    }
  }
}
