
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.data-import-page {
  padding: 0 0 7rem;
}
.data-import-header-title {
  margin: 0 !important;
}
.main-title,
.main-descr {
  margin: 0 0 1rem;
}
.column-download-file {
  min-width: 360px;
}
.upload-file-wrap {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: $winbox-uploaded-file;
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 5px;
  color: #fff;
  min-width: 100px;
  font-weight: 600;
  max-width: 300px;

  > span {
    @include text-overflow-ellipsis;
  }
}
.upload-file-label {
  display: inline-block;
  font-weight: bold;
  min-width: 100px;
}
.button-delete {
  @include button-reset-ui;
  margin: 0 0 0 7px;
}
.container {
  margin: auto;
  text-align: center;
  width: 50%;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }

  &-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
}
