
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-textarea {
  .winbox-label {
    @include form-input-label;
  }

  &.error {
    .textarea {
      border-color: $winbox-error;
      background: $winbox-icon-negative-background;

      &:focus {
        outline: $winbox-icon-negative-background;
      }
    }
  }

  &.has-label {
    ::v-deep {
      .vs__dropdown-toggle {
        padding: 5px 0 6px 0;
      }
    }
  }
}
.textarea {
  font-size: $winbox-font-size-main;

  &::placeholder {
    font-size: $winbox-font-size-small;
  }
}


