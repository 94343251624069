
.escalate-task {
  height: 200px;
}

.input-gap {
  margin-bottom: 1rem;
}

.textarea-gap {
  margin-top: 1rem;
}
