
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-work-history {
  padding-top: 0.5rem;

  ::v-deep {
    .card-content {
      background-color: $winbox-body-color;
      box-shadow: 0 3px 3px $winbox-body-color, 0 0 0 1px $winbox-body-color;
      padding: 1rem;
    }
    .main-data-table {
      min-height: 500px;

      thead {
        tr:not(.visible-thead-row) {
          display: none;
        }
        th {
          width: 150px;

          &:first-child {
            width: 200px;
          }
          &:nth-child(2) {
            width: 320px;
          }
          &:last-child {
            width: 110px;
            text-align: center;
          }
        }
      }
    }
    .action-button {
      @include button-reset-ui;
    }
  }
}
.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
