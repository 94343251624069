
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-upload-file {
  font-size: 18px;
  
  .file-label.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
