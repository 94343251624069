
@import "~@/../assets/stylesheets/variables/variables";

.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.add-entity-icon {
  cursor: pointer;
  font-size: $winbox-font-size-average;
  margin: 0 5px 0 0 !important;
  width: auto;
  height: auto;
}
.radio-status {
  display: inline-block;
  position: relative;
  top: 7px;
  left: 10px;
}
.status {
  color: $winbox-primary-active-bg-color;
  font-weight: bold;
}
