
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.entry-wrap {
  @include page-entry-wrap-style;
  min-height: calc(100vh - 100px);
}

.entry-top {
  color: #fff;
  ::v-deep {
    .title {
      color: #fff;
    }
  }
}
.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.item-count {
  @include search-count-styles;
  font-size: $winbox-font-size-main;
  min-width: 22px;
  max-width: 70px;
  @include text-overflow-ellipsis;
}
.switch-label {
  font-size: 12px;
  padding-right: 8px;
  color: $winbox-body-color;
}
.entry-base-information {
  margin: 0 0 1.5rem;
  color: $winbox-body-grey-bg;
}
.in-house-title {
  text-transform: uppercase;
  margin: 1rem 0 1rem -24px;
  padding: 0.5rem;
  font-size: $winbox-font-size-big;
  text-align: center;
  background: $winbox-secondary-title;
  color: $winbox-primary-text-color;
}
.column-route-view {
  background: $winbox-body-color;
  margin-left: -12px;
  min-height: 500px;
}
