
@import "~@/../assets/stylesheets/variables/variables";

.brand-contacts {
  padding-top: 0.5rem;
}

.main-data-table {
  ::v-deep {
    font-size: 13px;
    th {
      &:first-child {
        min-width: 150px;
      }
      &:last-child {
        min-width: 100px;
        width: 100px;
      }
    }
  }
}
