
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.company-agencies {
  @include agency-brand-relationships;

  ::v-deep {
    .main-data-table {
      font-size: 13px;
      thead {
        th {
          /* Status */
          &:nth-child(5) {
            width: 150px;
          }
        }
      }
      tbody {
        td[colspan="6"] {
          text-align: left !important;
        }
      }
    }
  }
}
.action-button {
  @include profile-action-button;
}
