
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-hierarchy-entity {
  ::v-deep {
    .card-header {
      background: $winbox-primary-active-bg-color;
    }
    .card-content {
      padding: 0;
      min-height: 200px;
    }
  }
}
.content {
  font-size: $winbox-font-size-less-than-main;
}
.please-select {
  font-style: italic;
}
.new-tag-input-wrap {
  margin: 0;
}
.hierarchy-item-list {
  .hierarchy-item {
    border-bottom: 1px solid $winbox-tag-border-color;
    margin: 0;

    &._inactive {
      color: $winbox-body-grey-bg;
      margin: 0;
    }

    @include no-user-select;
    @include button-delete-universal-hover;
    @include button-create-universal-hover;

    &:last-child {
      border-bottom: 0;
    }

    .item-indicator {
      width: 7px;
      height: 100%;
      background: $winbox-negative;
    }
  }
  .hierarchy-item-columns {
    padding: 7px;
  }
}
.action-right {
  text-align: right;
}
.button-remove,
.button-create {
  border: transparent;
  border-radius: 50%;
  font-size: 14px;

  @include button-delete-universal;
}
.button-remove {
  background: $winbox-icon-negative-background;
}
.no-data {
  padding: 7px;
}

.default {
  border-color: white;
  cursor: default;
}
