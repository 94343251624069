
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.item-draggable {
  @include card-draggable-icon;
}
.item-title {
  .winbox-title {
    margin: 0 0 1rem;
  }
}
.item-status {
  text-align: center;
  margin: 3rem 0 0;

  .item-status-icon {
    font-size: $winbox-font-size-huge;
  }
  .main-status {
    font-size: $winbox-font-size-average;
    font-weight: bold;
    margin: 1rem 0;
  }
  .additional-status {
    color: $winbox-secondary-title;
    font-weight: 600;
  }
}
