
@import "~@/../assets/stylesheets/variables/variables";

.main-container {
  min-width: $winbox-min-site-width;
}
.main-header {
  background: $winbox-primary-bg-color;
  color: #fff;
  padding: 10px 0;
  height: $winbox-header-height;
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 3;

  .logo {
    width: $winbox-nav-width;
    text-align: center;
    position: fixed;
  }
  .main-header-block {
    flex-grow: 1;
    padding: 0 6px 0 0;
    margin-left: $winbox-nav-width;
  }
}
.main-content-wrap {
  min-height: $winbox-main-content-min-height;
  display: flex;

  .main-menu {
    color: #fff;
    background: $winbox-nav-bg-color;
    width: $winbox-nav-width;
    min-width: $winbox-nav-width;
    display: flex;
    flex-direction: column;

    > div {
      &:first-child {
        flex-grow: 1;
      }
    }
  }
  .main-content {
    padding: $winbox-main-content-padding;
    flex-grow: 1;
    padding-top: 84px;

    &.profile-page {
      background: $winbox-sidebar-active-bg-color;

      ::v-deep {
        .breadcrumb {
          li.is-active {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.main-modal-bg {
  &.active {
    position: fixed;
    background: rgba(10, 10, 10, 0.4);
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
