
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.item-draggable {
  @include card-draggable-icon;
}
.item-title {
  .winbox-title {
    margin: 0 0 1rem;
  }
}
.item-count {
  font-weight: bold;
  font-size: $winbox-font-size-huge;
}
.item-status {
  font-size: $winbox-font-size-average;
  margin: 0 0 1rem;
}
.item-info-list-item {
  &.negative {
    .icon-block {
      background: $winbox-icon-negative-background;
    }
  }
}
.item-info-text {
  ::v-deep {
    .positive {
      color: $winbox-positive;
      font-weight: 600;
    }
    .negative {
      color: $winbox-negative;
      font-weight: 600;
    }
  }
}
.icon-block {
  display: inline-block;
  background: $winbox-icon-background;
  border-radius: 4px;
  padding: 4px;
}
.item-graph {
  ::v-deep {
    canvas {
      height: 220px !important;
    }
  }
}
