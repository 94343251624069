@import "~@/../assets/stylesheets/variables/variables";

.entity-locations {
  padding-top: 0.5rem;
  .main-data-table {
    ::v-deep {
    font-size: 13px;
      thead {
        th {
          &:last-child {
            width: 150px;
          }
        }
      }
    }
  }
}
