
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-label {
  @include form-input-label;
}
.winbox-input {
  &.invalid,
  &.error {
    .input {
      border-color: $winbox-error;
      background: $winbox-icon-negative-background;
      &:focus {
        outline: $winbox-icon-negative-background;
      }
    }
    .winbox-label {
      span {
        background: $winbox-error;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
}
.input {
  font-size: 14px;
  &::placeholder {
    font-size: $winbox-font-size-small;
  }
}
.has-label {
  .input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    box-shadow: none;
  }
}
.icon-box {
  height: 100% !important;
}
.max-length-message {
  color: red;
  font-size: 0.8rem;
}
