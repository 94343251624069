
@import "~@/../assets/stylesheets/variables/variables";

.wrap-user-avatar {
  padding: 8px 0;
  display: flex;
  justify-content: center;
  font-size: 28px;
  align-items: center;
  background: $winbox-primary-bg-color;
  border-top: 1px solid $winbox-sidebar-active-bg-color;
}
