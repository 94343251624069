
@import "~@/../assets/stylesheets/variables/variables";

.company-brands {
  padding-top: 0.5rem;
  .main-data-table {
    ::v-deep {
      font-size: 13px;
      thead {
        th {
          width: 200px;
        }
      }
      .vs__dropdown-toggle {
        border-color: transparent;
      }
    }
  }
}
