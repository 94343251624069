
@import "~@/../assets/stylesheets/variables/variables";

$padding-inside: 12px;
$transition-speed: 0.4s;

.notification {
  position: relative;
  padding: $padding-inside;
  margin-bottom: 20px;
  border-radius: 4px;
  opacity: 1;
  transition: all $transition-speed;
  color: #fff;

  &.with-close {
    padding-right: 45px;
  }

  &.fadeOut {
    opacity: 0;
  }

  &.dismiss {
    display: none;
  }

  .close-icon {
    position: absolute;
    top: $padding-inside;
    right: $padding-inside;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity $transition-speed;

    &:hover {
      opacity: 1;
    }
  }

  &.info {
    background-color: $winbox-success;
  }

  a {
    color: #fff;
  }
}
.notification-icon {
  margin-right: 10px;
}
.notification-content {
  display: inline-block;
}
