@import "~@/../assets/stylesheets/variables/variables";

// Common
@mixin no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

@mixin text-overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// Specific
@mixin card-draggable-icon {
  position: absolute;
  right: 1rem;
  top: 25px;
  font-size: 18px;
  cursor: pointer;
}

@mixin form-input-label {
  $margin-left: 8px;

  color: $winbox-body-grey-bg;
  position: absolute;
  margin: -12px 0 0 $margin-left;
  z-index: 1;
  width: calc(100% - #{$margin-left});
  font-weight: 400;
  @include text-overflow-ellipsis;

  span {
    background: #fff;
    font-size: 12px;
    padding: 0 1px;
  }
}

@mixin datatable-search-input {
  background: $winbox-input-light-bg;
  color: #fff;
  border-color: transparent;
  transition: all 0.3s;
  font-size: 16px;

  &::-webkit-input-placeholder {
    color: #fff;
  }
  &:focus {
    background: $winbox-body-color;
    color: $winbox-primary-bg-color;

    &::-webkit-input-placeholder {
      color: $winbox-secondary-title;
    }
  }
}

@mixin icon-wrapper-base-style {
  border-radius: 50%;
  font-size: $winbox-font-size-main;
  background: $winbox-icon-negative-background;
  border: transparent;
  cursor: pointer;
  display: inline-block;
}

@mixin page-entry-wrap-style {
  margin: -$winbox-main-content-padding;
  padding: $winbox-main-content-padding;
  min-height: $winbox-main-content-min-height;
}

@mixin default-input-margins {
  .winbox-input,
  .winbox-select,
  .winbox-textarea {
    margin: 0 0 1rem;
  }

  .columns {
    .winbox-input,
    .winbox-select,
    .winbox-textarea {
      margin: 0;
    }
  }
}

@mixin profile-card-content-base-styles {
  padding: 1rem;

  @include default-input-margins;
}

@mixin button-delete-universal {
  opacity: 0;
  transition: all 0.3s;
}
@mixin button-delete-universal-hover {
  &:hover {
    .button-delete,
    .button-remove {
      opacity: 1;
    }
  }
}
@mixin button-create-universal-hover {
  &:hover {
    .button-create {
      opacity: 1;
    }
  }
}
@mixin button-reset-ui {
  background: transparent;
  border: transparent;
  height: auto;
  padding: 0;
  width: auto;
}

@mixin button-create-table {
  border-color: transparent;
  background: transparent;
  .winbox-icon {
    font-size: 24px;
  }
}

@mixin profile-action-button {
  width: 100%;
  input {
    margin-right: 5px;
  }
}

@mixin input-icon-box {
  .is-right {
    background: $winbox-icon-bg;
    border: 1px solid #dbdbdb;
    border-radius: 0 3px 3px 0;

    .winbox-icon {
      color: $winbox-primary-text-color !important;
    }
  }
}

@mixin location-detail-page-styles {
  padding-top: 0.5rem;

  .save-button,
  .edit-button {
    background: green;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-right: 8px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .cancel-button,
  .remove-button {
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-left: 8px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }

  .main-winbox-card {
    margin: 0 0 1rem;
  }

  ::v-deep {
    .card-content {
      padding: 0.5rem;
    }
  }

  .main-data-table {
    margin: 0 0 2rem;
    ::v-deep {
      thead {
        th {
          &:last-child {
            width: 150px;
          }
        }
      }
    }
  }
}

@mixin search-count-styles {
  display: inline-block;
  padding: 3px 4px;
  margin: 0 0 0 5px;
  font-size: 11px;
  color: #585656;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: $winbox-primary-active-bg-color;
  border-radius: $winbox-border-radius;
}

@mixin entity-notes-main-table-styles {
  th,
  td {
    width: 200px;

    &:first-child {
      width: 50px;
    }
    &:nth-child(2) {
      width: 400px;
    }
    &:last-child {
      width: 110px;
    }
  }
  tbody {
    td {
      &:first-child {
        vertical-align: middle;
        .VueTables__child-row-toggler {
          margin: 0 0 10px;
        }
      }
    }
  }
  .VueTables__child-row {
    &:hover {
      td {
        background: #fff !important;
      }
    }
    > td {
      padding: 0;
      width: auto;
    }
  }
  .child-full-description-td {
    width: auto !important;
  }
  .child-spacer-td {
    width: 200px !important;
  }
  .additional-block {
    position: absolute;
    right: 20px;
    text-align: right;
    font-size: 12px;
  }
  .vs__dropdown-toggle {
    border-color: transparent;
  }
  .child-full-description {
    min-height: 40px;
    white-space: pre-wrap;
  }
}

@mixin agency-brand-relationships {
  padding-top: 0.5rem;
  .main-data-table {
    ::v-deep {
      tbody {
        td {
          &:last-child {
            text-align: center;
          }
        }
      }
      ::v-deep {
        .action-button {
          @include button-reset-ui;
        }
      }
    }
  }
}

@mixin search-row-element {
  background: #fff;
  border: 1px solid $winbox-tag-border-color;
  border-radius: $winbox-border-radius;
  position: relative;
}

@mixin person-table-widget-styles {
  th {
    width: 150px;

    &:last-child {
      width: 50px;
    }
  }
  th,
  td {
    &:last-child {
      text-align: center;
    }
  }
  tbody {
    tr {
      &:hover {
        .remove-button {
          opacity: 1;
        }
      }
    }
  }
  .card-content {
    padding: 1rem;
  }
  .main-data-table {
    thead {
      tr:not(.visible-thead-row) {
        display: none;
      }
    }
  }
  .create-button {
    @include button-create-table;
  }
  .action-button {
    @include button-reset-ui;
  }
  .remove-button {
    @include icon-wrapper-base-style;
    @include button-delete-universal;
  }
}
