
.dropdown-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.dropdown-item {
  flex: 1 0 calc(33.33% - 1rem);
  margin-right: 1rem;
}

.dropdown-item:nth-child(3n) {
  margin-right: 0;
}

.winbox-textarea {
  margin-top: 1rem;
}
