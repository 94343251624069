
@import "~@/../assets/stylesheets/variables/variables";

.wrap-sidebar-menu {
  padding: 10px 0 0 0;

  &.with-separator {
    .menu-link-item {
      &.router-link-active {
        &:before {
          background: $winbox-sidebar-active-bg-color;
          height: $winbox-sidebar-menu-item-width;
          width: 22px;
          position: fixed;
          content: "";
          margin-left: 27px;
          margin-top: 0;
        }
      }
    }
  }
}
.menu-list {
  position: fixed;
  z-index: 5;
  padding-top: 60px;
  width: $winbox-nav-width;
}
.menu-list-item {
  margin: 0 0 0.5rem;

  .menu-link-item {
    margin: 0 auto;
    width: $winbox-sidebar-menu-item-width;
    height: $winbox-sidebar-menu-item-width;
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    border-radius: $winbox-border-radius;

    .menu-link-icon {
      opacity: 0.7;
    }

    &:hover,
    &.router-link-active {
      background: $winbox-sidebar-active-bg-color;
      .menu-link-icon {
        opacity: 1;
      }
    }
  }
}
