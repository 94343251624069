
@import "~@/../assets/stylesheets/variables/variables";

.winbox-link-entities {
  height: 360px;
  overflow: auto;
}

.winbox-link-entities-table {
  ::v-deep {
    thead {
      th {
        vertical-align: middle;
        &:last-child {
          width: 130px;
        }
      }
      tr:not(.visible-thead-row) {
        display: none;
      }
      .select-all-box {
        display: flex;
        background: rgba(255, 255, 255, 0.3);
        align-items: center;
        border-radius: 4px;
        font-size: $winbox-font-size-small;
        padding: 2px 6px;
        justify-content: space-between;

        .select-button {
          box-shadow: none;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
      td {
        border-left: none;
        border-right: none;

        &:first-child {
          border-left: 1px solid $winbox-secondary-title;
        }
        &:last-child {
          border-right: 1px solid $winbox-secondary-title;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
    .select-button {
      border-color: $winbox-primary-active-bg-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;

      &.active {
        background: $winbox-primary-active-bg-color;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .winbox-icon {
        font-size: $winbox-font-size-small;
      }
    }
  }
}
