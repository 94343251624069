
@import "~@/../assets/stylesheets/variables/variables";

$filter-modal-padding: 8px;

.winbox-data-table-filter-modal {
  background: $winbox-body-color;
  font-size: 12px;
  padding: $filter-modal-padding;

  .button {
    font-size: 12px;
  }
  .filter-label {
    font-size: 12px;
    padding: $filter-modal-padding;
  }
}
.add-new-block {
  background: $winbox-body-grey-bg;
  margin: -$filter-modal-padding;
  padding: $filter-modal-padding;
  text-align: center;
}
.add-entity-icon {
  border-radius: 50%;
  font-size: 11px;
  background: $winbox-primary-active-bg-color;
  cursor: pointer;
}
