
.main-data-table {
  ::v-deep {
    font-size: 13px;
    thead {
      th {
        &:nth-child(2),
        &:nth-child(3) {
          width: 300px;
        }
        &:nth-child(4) {
          width: 150px;
        }
        &:nth-child(5) {
          min-width: 150px;
        }
        &:nth-last-child(2) {
          min-width: 216px;
          width: 216px;
        }
        &:last-child {
          min-width: 100px;
          width: 100px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
