
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.revenue-list {
  .icon-wrapper {
    @include icon-wrapper-base-style;
  }
  li {
    @include button-delete-universal-hover;
  }
}
.column-remove-entity {
  padding: $winbox-default-column-padding 0;
  text-align: center;
}
.button-delete {
  @include button-delete-universal;
}
