
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

::v-deep {
  .winbox-title {
    .title,
    .subtitle {
      font-weight: bold;
    }
  }
}
.filter-by-last-days {
  width: 220px;
  margin-left: auto;
}
.top-overview-cards {
  .item-count {
    font-size: $winbox-font-size-huge;
    line-height: 1;
    font-weight: bold;
    text-align: center;
  }
  .item-descr {
    font-size: 21px;
    color: $winbox-secondary-title;
    opacity: 0.7;
    text-align: center;
  }
  .item-title {
    margin: 0 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.item-draggable {
  @include card-draggable-icon;
}
.align-right {
  margin-left: auto;
  margin-right: 0;
}
.date-picker-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.75rem 0 0;
}
.clear-date-btn {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 10px;
}
.clear-date-btn:hover {
  background-color: #e0e0e0;
}
