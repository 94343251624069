@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.audit-log-wrap {
  padding-top: 0.5rem;
  .main-title {
    margin: 0 0 1rem;
  }
  .main-data-table {
    ::v-deep {
      thead {
        th {
          width: 150px;

          &:last-child {
            width: 50px;
          }
        }
      }
    }
  }
}
