
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-brands {
  ::v-deep {
    .card-content {
      padding: 1rem;
    }
    .create-button {
      @include button-create-table;
    }
    .main-data-table {
      font-size: 12px;
      thead {
        th {
          &:last-child {
            width: 60px;
          }
        }
        tr:not(.visible-thead-row) {
          display: none;
        }
      }
      tbody {
        td {
          &:last-child {
            text-align: center;
          }
        }
        tr {
          &:hover {
            .remove-button {
              opacity: 1 !important;
            }
          }
        }
      }
      .remove-button {
        @include icon-wrapper-base-style;
        @include button-delete-universal;
      }
    }
  }
}
