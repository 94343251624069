
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-data-table {
  // All themes
  ::v-deep {
    #VueTables_th--id {
      width: 30px;
    }

    .table {
      th {
        background: $winbox-table-header;
        color: #fff;
        vertical-align: middle;
        @include break-word;
      }
      td {
        @include break-word;
        a {
          color: #3D589B;
        }
        a:hover {
          text-decoration: underline;
          color: #3D589B;
          font-weight: bold;
        }

        &:focus {
          outline: none;
        }
      }
    }
    .VueTables__sort-icon {
      font-size: 18px;
    }
    .VueTables__child-row-toggler {
      font-family: "Font Awesome 5 Free";
      cursor: pointer;
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;
      display: block;
      margin: auto;
      text-align: center;
    }
    .VueTables__child-row-toggler--closed::before {
      content: "\f078";
    }
    .VueTables__child-row-toggler--open::before {
      content: "\f077";
    }
    // Native filters
    .VueTables--server {
      > .columns:first-child {
        background: $winbox-primary-active-bg-color;
        margin: 0;
        position: relative;
        overflow: hidden;

        > .column {
          padding-bottom: 0;
        }

        .VueTables__search {
          width: 90%;
          .VueTables__search-field {
            width: 100%;
          }
        }
        .VueTables__search-field {
          label {
            display: none;
          }
          input {
            @include datatable-search-input;
          }
        }
        .VueTables__limit-field {
          padding-right: 5px;

          label {
            display: none;

            + select {
              height: 34px;
            }
          }
        }
      }
    }
  }
  // Specific themes
  &.winbox-theme {
    ::v-deep {
      .table {
        th {
          border: 0;
        }
        td {
          border: 0;
        }
      }
    }
  }
  &.with-borders-theme {
    ::v-deep {
      .table {
        th {
          border-color: $winbox-table-header;
        }
        td {
          border-color: $winbox-secondary-title;
        }
      }
    }
  }
}
