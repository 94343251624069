
.winbox-popover {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #fff;
  width: 75% !important;

  padding: 9px 14px;
  padding-top: 20px;
  margin-top: 150px;

  position: fixed;
  transition: top 0.5s ease 0s, height 0.5s ease 0s;
  top: 0;
  left: 305px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

  .item {
    cursor: pointer;
  }

  .active {
    color: #00C0B5;
  }

  .filter {
    height: 24px;
    padding: 5px;
  }

  .selectedFilter {
    color: #00C0B5;
    margin: -5px;
  }

  .close {
    position: absolute;
    float: initial;
    right: 10px;
    top: 2px;
  }

  ul {
    margin-bottom: 10px;
  }

  & > .arrow {
    left: -32px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999999;
    border-right-color: rgba(0, 0, 0, 0.05);
  }

  & > .arrow {
    border-width: 11px;
  }

  & > .arrow,
  & > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  & > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff;
  }

  & > .arrow:after {
    border-width: 10px;
    content: "";
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  user-select: none;
}

.btn-select-all {
  border: 1px solid #1DA0F2;
  color: #1DA0F2;
  background: none;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}

.btn-clear-filter {
  background: none;
  border: 1px solid #d9534f;
  color: #d9534f;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0;
}

.close {
  cursor: pointer;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
