
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-profile {
  .save-button,
  .edit-button {
    background: green;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-right: 16px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .cancel-button {
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.avatar-wrap {
  position: relative;

  &:hover {
    .add-avatar-action {
      opacity: 1;
    }
  }
}
.add-avatar-action {
  position: absolute;
  bottom: -23px;
  right: 9px;
  background: transparent;
  border-color: transparent;
  opacity: 0;
}
.avatar-img-box {
  border: 1px solid $winbox-secondary-title;
  border-radius: 50%;
  overflow: hidden;
  width: 82px;
  height: 82px;
  margin: 0 1.5rem 1.5rem 0;
  display: flex;
}
.action-button {
  @include profile-action-button;
}
.active-icon-bg {
  ::v-deep {
    @include input-icon-box;
  }
}
.profile-tab-content {
  min-height: 400px;
}
.error-block {
  color: $winbox-error;
  margin: 0 0 1.5rem;
}

.icon {
  float: right;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.pb-0 {
  padding-bottom: 0;
}

fieldset {
  position: relative;
  top: -8.5px;
  min-width: 0;
  margin: 0;
  border: 0;
  height: 48px;
}

.email-link {
  position: relative;
  left: 8.5px;
  top: 9px;
}

.email-container {
  background-color: whitesmoke;
  border-radius: 4px;
  height: 40px;
  position: relative;
  top: -7px;
}

.w-auto {
  width: auto!important;
}
.float-none {
  float: none!important;
}
legend {
  background-color: white;
  color: $winbox-body-grey-bg;
  font-size: 12px;
  height: 15px;
  line-height: inherit;
  padding: 0 2px;
  position: relative;
  left: 7px;
  top: 2px;
  z-index: 2;
}

.icon-container {
  float: right;
  height: 40px;
  position: relative;
  padding: 9px;
  background: #e9eaeb;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
