
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.item-draggable {
  @include card-draggable-icon;
}
.item-title {
  .winbox-title {
    margin: 0 0 1rem;
  }
}
.item-label {
  ::v-deep {
    .subtitle {
      font-weight: 300;
      color: $winbox-secondary-title;
    }
  }
}
.item-graph {
  ::v-deep {
    canvas {
      height: 220px !important;
    }
  }
}
