
@import "~@/../assets/stylesheets/variables/variables";

aside.search-options {
  position: fixed;
  z-index: 2;
  height: auto;
  margin: -10px 0 0.5rem;
  min-height: 100%;
  background: #fff;
  width: 220px;
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 0 0 80px 0;
  color: #fff;
  overflow: visible !important;
  left: 76px;
  top: 70px;

  .filter-buttons-shared {
    background: none;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
  }

  .btn-filter-options {
    @extend .filter-buttons-shared;
    color: $winbox-search-filter-text-color;
    width: 100%;
    padding: 12px;
    border: 0;
    border-bottom: 1px solid #E6E9EE;
  }

  .btn-filter,
  .btn-filter-disabled {
    @extend .btn-filter-options;
    cursor: pointer;
  }

  .btn-filter {
    .far {
      margin-right: 5px;
      font-size: 15px;
      &.fa-check-square {
        color: $winbox-primary-active-bg-color;
      }
    }

    &:focus {
      background: none;
      color: $winbox-search-filter-text-color;
    }
    &:hover, &.active {
      background: $winbox-primary-active-bg-color;
      color: #FFF;
      .far {
        color: #FFF;
      }
    }
  }
}
