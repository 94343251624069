
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.columns {
  width: 100%;
}

.each-column {
  display: block;
  flex-basis: 20%;
  padding: 0.75rem;
}

.detail-column {
  flex-basis: 40%;
}

.status-column {
  flex-basis: 15%;
}
