
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-select {
  position: relative;

  .winbox-label {
    @include form-input-label;
  }

  &.has-label {
    ::v-deep {
      .vs__dropdown-toggle {
        padding: 5px 0 6px 0;
      }
    }
  }

  &.error {
    ::v-deep {
      .vs__dropdown-toggle {
        border-color: $winbox-error;
        background: $winbox-icon-negative-background;
      }
    }
    .winbox-label {
      span {
        background: $winbox-error;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
  ::v-deep {
    .vs__dropdown-menu {
      min-width: auto;
    }
    .vs__search {
      &::placeholder {
        font-size: $winbox-font-size-small;
        color: $winbox-input-placeholder;
      }
    }
    .vs__dropdown-option--highlight {
      background: $winbox-primary-active-bg-color;
    }
    .vs__dropdown-option {
      padding: 3px 15px;
    }
  }

  &.green-theme {
    ::v-deep {
      .vs__dropdown-toggle {
        background: $winbox-primary-active-bg-color;
        border-color: transparent;
        color: #fff;
      }
      .vs__search,
      .vs__search:focus,
      .vs__selected {
        color: #fff;
      }
      .vs__open-indicator {
        fill: #fff;
      }
    }
  }
}
