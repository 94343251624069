@import "~@/../assets/stylesheets/variables/mixins/mixins";

.business-entity-profile-wrap {
  ::v-deep {
    .card-content {
      @include profile-card-content-base-styles;
    }
  }
  .save-button,
  .edit-button {
    background: green;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-right: 16px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .cancel-button {
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.website-container {
  background-color: whitesmoke;
  border-radius: 4px;
  height: 40px;
  position: relative;
  top: -7px;
}
.website-link {
  position: relative;
  left: 8.5px;
  top: 9px;
}
fieldset {
  position: relative;
  top: -8.5px;
  min-width: 0;
  margin: 0;
  border: 0;
  height: 48px;
}
.w-auto {
  width: auto!important;
}
.float-none {
  float: none!important;
}
legend {
  background-color: white;
  color: $winbox-body-grey-bg;
  font-size: 12px;
  height: 15px;
  line-height: inherit;
  padding: 0 2px;
  position: relative;
  left: 7px;
  top: 2px;
  z-index: 2;
}
.pb-0 {
  padding-bottom: 0;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  gap: 8px;
}
