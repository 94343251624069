
@import "~@/../assets/stylesheets/variables/variables";

::v-deep {
  .v--modal-overlay {
    .v--modal-box {
      overflow: auto;
    }
  }
}

.winbox-modal-header {

  background: $winbox-primary-active-bg-color;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: $winbox-font-size-base;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.winbox-modal-header-icon {
  .button {
    background: transparent;
    border-color: transparent;
    &:focus {
      border-color: #fff;
    }
  }
  ::v-deep {
    .winbox-icon {
      font-size: $winbox-font-size-big;
    }
  }
}
.winbox-modal-container,
.winbox-footer-buttons {
  padding: 1rem;
}
.winbox-footer-buttons {
  display: flex;
  justify-content: space-between;
}

.winbox-modal-scrollable {
  .winbox-footer-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .winbox-modal-container {
    overflow: auto;
    position: absolute;
    width: 100%;
    bottom: 68px;
    top: 52px;
  }
}
