
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-global-progress {
  position: fixed;
  z-index: 5;
  width: 100%;

  .progress {
    border-radius: 0;
    height: 5px;
  }
}
