
@import "~@/../assets/stylesheets/variables/variables";

.winbox-card {
  border-radius: $winbox-border-radius;
  height: 100%;

  .card-header {
    background: $winbox-nav-bg-color;
    border-radius: $winbox-border-radius $winbox-border-radius 0 0;

    .card-header-title {
      font-weight: 400;
      color: $winbox-body-color;
    }
    ::v-deep {
      .winbox-icon {
        font-size: 24px;
      }
    }
    .card-header-icon {
      &._expand {
        .button {
          border-radius: 50%;
          border-color: transparent;
          font-size: 11px;
        }
        ::v-deep {
          .winbox-icon {
            font-size: 19px;
          }
        }
      }
    }
  }
}
