
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.public-notes-wrap {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
.active-icon-bg {
  ::v-deep {
    @include input-icon-box;
  }
}
