
.main-title {
  margin: 0 0 1rem;
}
.main-data-table {
  ::v-deep {
    thead {
      th {
        width: 130px;

        &:last-child {
          width: 80px;
        }
      }
    }
  }
}
