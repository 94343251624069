
.app-header {
  display: flex;
  ::v-deep {
    .search-main {
      flex-grow: 1;
      display: flex;
    }
  }
}
