
.brand-sponsorships {
  ::v-deep {
    .main-data-table {
      font-size: 13px;
      thead {
        th {
          /* Status */
          &:nth-child(6) {
            width: 150px;
          }
          /* Placement */
          &:nth-child(3) {
            width: 150px;
          }
        }
      }
      tbody {
        td[colspan="6"] {
          text-align: left !important;
        }
      }
    }
  }
}
