
.main-data-table {
  ::v-deep {
    font-size: 13px;
    thead {
      th {
        &:nth-last-child(2) {
          width: 165px;
        }
        &:last-child {
          width: 100px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
