
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-entity {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
.priority {
  max-width: 85px;
}
