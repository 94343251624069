
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.logo-wrap {
  position: relative;

  &:hover {
    .add-logo-action {
      opacity: 1;
    }
  }
}
.add-logo-action {
  position: absolute;
  left: 60px;
  top: 60px;
  background: transparent;
  border-color: transparent;
  opacity: 0;
}
.logo-img-box {
  border: 1px solid $winbox-secondary-title;
  border-radius: 50%;
  overflow: hidden;
  width: 82px;
  height: 82px;
  margin: 0 1.5rem 1.5rem 0;
  display: flex;
}
