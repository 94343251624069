
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.change-password {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
  .field-label {
    font-weight: bold;
    font-size: $winbox-font-size-less-than-main;
    color: $winbox-sidebar-active-bg-color;
  }
}
